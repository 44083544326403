import PropTypes from "prop-types";
import React from "react";

import {
    PassThroughStateObserver,
    connectDataStream
} from "@ts/datastream";

import { useFormattedText } from "@ts/react-text";

import {
    EVENT_KEYS_CREATE_CASE,
    STATE_KEYS_CREATE_CASE
} from "../dataStreamConfig.js";

import {
    CreateCaseIntro,
    CreateCaseStepAgreements,
    CreateCaseStepFindings,
    CreateCaseStepFinish,
    CreateCaseStepImageData,
    CreateCaseStepInformation,
    CreateCaseUploadModal
} from "@neurovis/ui-components";

const STEP_INDEX_COMPONENT_MAP = {
    "-1": CreateCaseIntro,
    "0": CreateCaseStepAgreements,
    "1": CreateCaseStepFindings,
    "2": CreateCaseStepInformation,
    "3": CreateCaseStepImageData,
    "4": CreateCaseStepFinish
};

const CreateCaseComponent = props => {
    const {
        [STATE_KEYS_CREATE_CASE.STEP_INDEX]: stepIndex,
        getFormattedTextHook
    } = props;
    const StepComponent = STEP_INDEX_COMPONENT_MAP[stepIndex];

    const { getFormattedText } = useFormattedText();
    getFormattedTextHook( getFormattedText );

    return (
        <React.Fragment>
            <StepComponent />
            <CreateCaseUploadModal />
        </React.Fragment>
    );
};

CreateCaseComponent.propTypes = {
    [STATE_KEYS_CREATE_CASE.STEP_INDEX]: PropTypes.number.isRequired
};

const getEmitters = ( dataStream, props ) => ( {
    getFormattedTextHook: getFormattedText => dataStream
            .eventStream[EVENT_KEYS_CREATE_CASE.GET_FORMATTED_TEXT_HOOK].emit( getFormattedText )
} );

const getObservers = ( dataStream, props ) => [
    new PassThroughStateObserver( STATE_KEYS_CREATE_CASE.STEP_INDEX )
];

const CreateCaseContainer = connectDataStream( {
    getObservers,
    getEmitters
} )( CreateCaseComponent );


export { CreateCaseContainer };