import ZipperWorker from "./zipper.worker.js";

const readFileListAsArrayBuffers = async fileList => {
    const buffersPrimises = [];
    for ( let i = 0; i < fileList.length; i++ ) {
        buffersPrimises.push( readFileAsArrayBuffer( fileList[i] ) );
    }
    return await Promise.all( buffersPrimises );
};

const getFileNamesFromFileList = fileList => {
    const fileNames = [];
    for ( let i = 0; i < fileList.length; i++ ) {
        fileNames.push( fileList[i].name );
    }
    return fileNames;
};

const readFileAsArrayBuffer = file => new Promise( ( resolve, reject ) => {
    const fileReader = new FileReader();
    fileReader.onload = event => resolve( event.target.result );
    fileReader.readAsArrayBuffer( file );
} );

const zipArrayBuffers = ( {
    fileArrayBuffers = [],
    fileNames = [],
    compressionLevel = 0,
    transfer = false
} = {} ) => new Promise( ( resolve, reject ) => {

    const zipperWorker = new ZipperWorker();

    // we always transfer the buffers to the worker
    //  if transfer is set to false we will transfer a copy of the ArrayBuffers
    const fileArrayBuffersTransfer = transfer ?
        fileArrayBuffers : fileArrayBuffers.map( fileArrayBuffer => fileArrayBuffer.slice() );

    const fileNamesTransfer = fileNames && fileNames.length === fileArrayBuffers.length ?
        fileNames : fileArrayBuffers.map( ( e, index ) => index.toString() );

    zipperWorker.postMessage( {
        fileArrayBuffers: fileArrayBuffersTransfer,
        fileNames: fileNamesTransfer,
        compressionLevel
    }, fileArrayBuffersTransfer );

    zipperWorker.addEventListener( "message", message => {
        zipperWorker.terminate();
        resolve( message.data );
    } );
} );

export {
    getFileNamesFromFileList,
    readFileListAsArrayBuffers,
    readFileAsArrayBuffer,
    zipArrayBuffers
};
