import PropTypes from "prop-types";
import React from "react";

import {
    PassThroughStateObserver,
    connectDataStream
} from "@ts/datastream";

import {
    APP_LOADER_TYPE,
    AppLoader,
    PlansOverview
} from "@neurovis/ui-components";

import { STATE_KEYS_PLAN_MANAGEMENT } from "../dataStreamConfig.js";

const PlanManagementComponent = props => {
    const { [STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS]: casePlans } = props;

    const isInitializing = !casePlans;

    const loadingElement = isInitializing ? (
        <AppLoader appLoaderType={APP_LOADER_TYPE.CASE}/>
    ) : null;

    const plansOverviewElement = !isInitializing ? (
        <PlansOverview />
    ) : null;

    return (
        <React.Fragment>
            {loadingElement}
            {plansOverviewElement}
        </React.Fragment>
    );
};

PlanManagementComponent.propTypes = {
    [STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS]: PropTypes.arrayOf( PropTypes.object )
};

const getObservers = ( dataStream, props ) => [
    new PassThroughStateObserver( STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS )
];

const PlanManagementContainer = connectDataStream( { getObservers } )( PlanManagementComponent );


export { PlanManagementContainer };