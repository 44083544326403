import {
    PassThroughStateObserver,
    connectDataStream,
    provideDataStream
} from "@ts/datastream";
import { STATE_KEYS_APP } from "../dataStreamConfig.js";


import {
    ICON_TYPE,
    Icon,
    Message,
    SIZE_TYPE
} from "@neurovis/pattern-library";


import { FormattedText } from "@ts/react-text";
import React, {
    useEffect, useState
} from "react";

const DownloadZIP = connectDataStream( {
    getObservers: () => [
        new PassThroughStateObserver( STATE_KEYS_APP.API_CLIENT ),
        new PassThroughStateObserver( STATE_KEYS_APP.CASE_UUID ),
        new PassThroughStateObserver( STATE_KEYS_APP.CASE_NAME ),
        new PassThroughStateObserver( STATE_KEYS_APP.CASE_NOTES )
    ]
} )( ( {
    [STATE_KEYS_APP.API_CLIENT]: apiClient,
    [STATE_KEYS_APP.CASE_UUID]: caseUUID,
    [STATE_KEYS_APP.CASE_NAME]: caseName,
    [STATE_KEYS_APP.CASE_NOTES]: caseNotes
} ) => {

    const [ url, setUrl ] = useState( null );
    useEffect( () => {
        ( async() => {
            if ( !url ) {
                const response = await apiClient.apis.operationPlan.getPlanFile( {
                    caseUUID,
                    planUUID: "NULL", // there are no plans in aneurysm cases
                    fileType: "vesselTree.zip"
                } );
                const url = window.URL.createObjectURL( response.data );
                setUrl( url );
            }
        } )();
    }, [] );


    const download = () => {
        const a = document.createElement( "a" );
        a.style.display = "none";
        a.href = url;
        a.download = "vesselTree.zip";
        document.body.appendChild( a );
        a.click();
        a.remove();
    };

    const buttonActive = !!url;
    return <div style={{
        display: "flex",
        placeContent: "center",
        padding: 20
    }}>
        <Message size={SIZE_TYPE.SMALL}>

            <div className="reselect" style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gap: "var(--smallTextFontSize)"
            }}>
                <div><FormattedText id="createCaseSummaryName" /></div>
                <div>{caseName}</div>


                <div><FormattedText id="createCaseSummaryDiagnosis" /></div>

                <div><FormattedText id="createCaseFindingsAneurysmTitle" /> - <FormattedText id="createCaseFindingsAneurysmSubtitle" /></div>

                <div><FormattedText id="createCaseSummaryNotes" /></div>
                <div>{caseNotes}</div>

                <div style={{ textAlign: "center", gridColumn: "1 / span 2" }}>
                    <button onClick={download} disabled={!buttonActive} style={{
                        cursor: buttonActive ? "pointer" : "wait",
                        width: "100%",
                        padding: 5,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10
                    }}>
                        {buttonActive ?
                            <Icon id={ICON_TYPE.FILE} size={"large"}/> :
                            <Icon id={ICON_TYPE.HOURGLASS_HALF} size={"large"}/>
                        }
                        vesselTree.zip

                    </button>
                </div>
            </div>
        </Message>
    </div>;
} );

export class DownloadResultsController {
    constructor( datastream ) {

        this.datastream = datastream;
        this._component = provideDataStream( datastream )( DownloadZIP );
    }

    getComponent() {
        return this._component;
    }
}