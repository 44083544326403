import {
    DataStream,
    createDataStreamElements,
    provideDataStream
} from "@ts/datastream";

import {
    STATE_APP_DEFAULTS,
    STATE_KEYS_APP
} from "../dataStreamConfig.js";

import { createApiClient } from "../apiClientUtils.js";

import { AppContainer } from "./AppContainer.jsx";

const AppController = class {
    constructor() {
        console.log( "AppController.constructor" );

        this._dataStream = new DataStream();
        createDataStreamElements( {
            dataStream: this._dataStream,
            stateKeys: STATE_KEYS_APP,
            defaultStates: STATE_APP_DEFAULTS
        } );

        this._component = provideDataStream( this._dataStream )( AppContainer );

        this.init();
    }

    get dataStream() {
        return this._dataStream;
    }

    getComponent() {
        return this._component;
    }

    async init() {
        console.log( "AppController.init" );

        const { stateStream } = this._dataStream;

        await this.initApiClient();

        const stateCaseUUID = stateStream[STATE_KEYS_APP.CASE_UUID];
        if ( stateCaseUUID.current ) {
            this.initCase();
        } else {
            // wait for caseUUID be defined
            // TODO: maybe we should bake this functionality in our ts-datastream
            // await streamElement.untilNotNull() or something similar?
            const setCaseReady = () => {
                stateCaseUUID.unsubscribe( setCaseReady );
                this.initCase();
            };
            stateCaseUUID.subscribe( setCaseReady );
        }
    }

    async initCase() {
        console.log( "AppController.initCase" );

        const { stateStream } = this._dataStream;

        const stateCaseName = stateStream[STATE_KEYS_APP.CASE_NAME];
        const stateCaseNotes = stateStream[STATE_KEYS_APP.CASE_NOTES];
        const stateCaseDiagnosis = stateStream[STATE_KEYS_APP.CASE_DIAGNOSIS];
        const stateCasePresetNumber = stateStream[STATE_KEYS_APP.CASE_PRESET_NUMBER];

        const stateCaseStatus = stateStream[STATE_KEYS_APP.CASE_STATUS];

        const stateSurgeonFirstName = stateStream[STATE_KEYS_APP.SURGEON_FIRST_NAME];
        const stateSurgeonLastName = stateStream[STATE_KEYS_APP.SURGEON_LAST_NAME];

        const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
        const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

        const {
            getCase,
            getCaseSurgeon
        } = apiClient.apis.operationManage;

        // fetch global state and surgeon information from api,
        //  which also decides the current case workflow / status
        let surgeonData;
        let caseData;
        try {
            const [
                caseDataResponse,
                surgeonDataResponse
            ] = await Promise.all( [
                getCase( { caseUUID } ),
                getCaseSurgeon( { caseUUID } )
            ] );
            caseData = caseDataResponse.obj;
            surgeonData = surgeonDataResponse.obj;
        } catch ( err ) {
            // TODO: add error case
            console.error( "Could not retrieve case from server.", err );
            return;
        }

        const {
            name,
            notes,
            status,
            diagnosis,
            presetNumber
        } = caseData;

        stateCaseName.emit( name );
        stateCaseNotes.emit( notes );
        stateCaseStatus.emit( status );
        stateCaseDiagnosis.emit( diagnosis );
        stateCasePresetNumber.emit( presetNumber );

        const {
            firstName,
            lastName
        } = surgeonData;

        stateSurgeonFirstName.emit( firstName );
        stateSurgeonLastName.emit( lastName );
    }

    async initApiClient() {
        console.log( "AppController.initApiClient" );

        const { stateStream } = this._dataStream;

        const stateApiClient = stateStream[STATE_KEYS_APP.API_CLIENT];
        const stateVersionApi = stateStream[STATE_KEYS_APP.VERSION_API];

        const securityToken = stateStream[STATE_KEYS_APP.SECURITY_TOKEN].current;
        const apiEndpoint = stateStream[STATE_KEYS_APP.API_ENDPOINT].current;
        const apiOrigin = stateStream[STATE_KEYS_APP.API_ORIGIN].current;

        const apiClient = await createApiClient( {
            securityToken,
            apiEndpoint,
            apiOrigin
        } );

        if ( !apiClient ) {
            return;
        }

        try {
            const { getServiceInformation } = apiClient.apis.service;
            const serviceInformationResponse = await getServiceInformation();
            const { serviceVersion } = serviceInformationResponse.obj;

            stateVersionApi.emit( serviceVersion );
        } catch ( err ) {
            // TODO: add error case
            console.error( "Could not retrieve api service information from server.", err );
            return;
        }

        stateApiClient.emit( apiClient );
    }
};

export { AppController };