import packageJson from "../package.json";

import { retrieveSecurityToken } from "./apiClientUtils.js";

import {
    MODALITY_TYPE,
    SEX_TYPE
} from "@neurovis/config";

const STATE_KEYS_APP = {
    APP_LANGUAGE: "appLanguage",
    SECURITY_TOKEN: "securityToken",

    API_ENDPOINT: "apiEndpoint",
    API_ORIGIN: "apiOrigin",
    API_CLIENT: "apiClient",

    VERSION_API: "versionApi",
    VERSION_APP: "versionApp",

    // states: case information
    // ---

    CASE_UUID: "caseUuid",
    CASE_NAME: "caseName",
    CASE_NOTES: "caseNotes",
    CASE_STATUS: "caseStatus",
    CASE_DIAGNOSIS: "caseDiagnosis",
    CASE_PRESET_NUMBER: "casePresetNumber",

    SURGEON_FIRST_NAME: "surgeonFirstName",
    SURGEON_LAST_NAME: "surgeonLastName",

    PATIENT_AGE: "patientAge",
    PATIENT_SEX: "patientSex"
};

const STATE_APP_DEFAULTS = {
    API_ENDPOINT: "/api/api-docs",
    API_ORIGIN: window.location.origin,
    SECURITY_TOKEN: retrieveSecurityToken(),
    APP_LANGUAGE: "en",
    VERSION_APP: packageJson.version,
    PATIENT_SEX: SEX_TYPE.NOT_SPECIFIED
};

const STATE_KEYS_CREATE_CASE = {
    STEP_INDEX: "stepIndex",
    UPLOAD_MODAL_OPEN: "uploadModalOpen",
    UPLOAD_STATUS: "uploadStatus",
    COMPLETE_AGREEMENT: "stepCompleteAgreement",
    COMPLETE_FINDINGS: "stepCompleteFindings",
    COMPLETE_INFORMATION: "stepCompleteInformation",
    COMPLETE_IMAGEDATA: "stepCompleteImageData",
    APPROVED_TAC: "approvedTermsAndConditions",
    APPROVED_DPA: "approvedDataProcessingAgreement",
    PRESET_OPTIONAL_STRUCTURES: "presetOptionalStructuresChecked",
    CASE_NAME_VALID: "caseNameValid",
    PATIENT_AGE_VALID: "patientAgeValid",
    SELECTED_MODALITIES: "selectedModalities"
};

const STATE_CREATE_CASE_DEFAULTS = {
    STEP_INDEX: 0, // setting the initial step index to -1 will show the intro page
    UPLOAD_MODAL_OPEN: false,
    COMPLETE_AGREEMENT: false,
    COMPLETE_FINDINGS: false,
    COMPLETE_INFORMATION: false,
    COMPLETE_IMAGEDATA: false,
    APPROVED_TAC: false,
    APPROVED_DPA: false,
    PRESET_OPTIONAL_STRUCTURES: [],
    CASE_NAME_VALID: false,
    PATIENT_AGE_VALID: true,
    SELECTED_MODALITIES: [
        MODALITY_TYPE.MPRAGE,
        MODALITY_TYPE.FLAIR
    ]
};

Object.values( MODALITY_TYPE ).map( modalityType => {
    STATE_KEYS_CREATE_CASE[`FILES_${modalityType}`] = `files_${modalityType}`;
    STATE_KEYS_CREATE_CASE[`FILES_VALID_${modalityType}`] = `filesValid_${modalityType}`;
    STATE_CREATE_CASE_DEFAULTS[`FILES_VALID_${modalityType}`] = false;

    STATE_KEYS_CREATE_CASE[`IMAGE_DATA_${modalityType}`] = `imageData_${modalityType}`;

    STATE_KEYS_CREATE_CASE[`ACQ_DATE_${modalityType}`] = `acqDate_${modalityType}`;
    STATE_KEYS_CREATE_CASE[`ACQ_DATE_VALID_${modalityType}`] = `acqDateValid_${modalityType}`;
    STATE_CREATE_CASE_DEFAULTS[`ACQ_DATE_VALID_${modalityType}`] = true;
} );

const EVENT_KEYS_CREATE_CASE = {
    CREATE_CASE: "createCase",
    CHANGE_STEP: "changeStep",
    RESET_DIAGNOSIS: "resetDiagnosis",
    START_UPLOAD: "startUpload",
    GET_FORMATTED_TEXT_HOOK: "getFormattedTextHook"
};

const STATE_KEYS_PLAN_MANAGEMENT = {
    CASE_PLANS: "casePlans",
    DATA_APPROVED: "dataApproved",
    PLAN_MANAGEMENT_LOADING: "planManagementLoading",

    PLANNER_ENDPOINT: "plannerEndpoint",
    PLANNER_ORIGIN: "plannerOrigin"
};
const STATE_PLAN_MANAGEMENT_DEFAULTS = {
    DATA_APPROVED: false,
    PLAN_MANAGEMENT_LOADING: false,

    PLANNER_ENDPOINT: "/planner",
    PLANNER_ORIGIN: window.location.origin
};
const EVENT_KEYS_PLAN_MANAGEMENT = {
    REVIEW_DATA: "reviewData",
    ADD_PLAN: "addPlan",
    RENAME_PLAN: "renamePlan",
    COPY_PLAN: "copyPlan",
    REMOVE_PLAN: "removePlan",
    LOCK_PLAN: "lockPlan",
    OPEN_PLAN: "openPlan"
};

export {
    STATE_KEYS_APP,
    STATE_APP_DEFAULTS,
    STATE_KEYS_CREATE_CASE,
    STATE_CREATE_CASE_DEFAULTS,
    EVENT_KEYS_CREATE_CASE,
    STATE_KEYS_PLAN_MANAGEMENT,
    STATE_PLAN_MANAGEMENT_DEFAULTS,
    EVENT_KEYS_PLAN_MANAGEMENT
};