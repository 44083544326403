import {
    DataStream,
    createDataStreamElements,
    provideDataStream
} from "@ts/datastream";

import {
    EVENT_KEYS_PLAN_MANAGEMENT,
    STATE_APP_DEFAULTS,
    STATE_KEYS_APP,
    STATE_KEYS_PLAN_MANAGEMENT,
    STATE_PLAN_MANAGEMENT_DEFAULTS
} from "../dataStreamConfig.js";

import { PlanManagementContainer } from "./PlanManagementContainer.jsx";

import {
    onAddPlan,
    onCopyPlan,
    onLockPlan,
    onOpenPlan,
    onRemovePlan,
    onRenamePlan,
    onReviewData
} from "./eventCallbacks.js";

const indexByUUID = ( array, uuid ) => array.findIndex( item => item.uuid === uuid );

const PlanManagementController = class {
    constructor( parentDataStream ) {
        console.log( "PlanManagementController.constructor" );

        this._parentDataStream = parentDataStream;
        this._localDataStream = new DataStream();
        createDataStreamElements( {
            dataStream: this._localDataStream,
            stateKeys: STATE_KEYS_PLAN_MANAGEMENT,
            defaultStates: STATE_PLAN_MANAGEMENT_DEFAULTS,
            eventKeys: EVENT_KEYS_PLAN_MANAGEMENT
        } );

        this._dataStream = new DataStream();
        this._dataStream.merge( this._parentDataStream );
        this._dataStream.merge( this._localDataStream );

        this._component = provideDataStream( this._dataStream )( PlanManagementContainer );

        this.init();
    }

    get dataStream() {
        return this._dataStream;
    }

    getComponent() {
        return this._component;
    }

    async init() {
        console.log( "PlanManagementController.init" );

        const { eventStream } = this._dataStream;

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.REVIEW_DATA]
                .subscribe( ( { data } ) => onReviewData( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.ADD_PLAN]
                .subscribe( ( { data } ) => onAddPlan( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.RENAME_PLAN]
                .subscribe( ( { data } ) => onRenamePlan( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.COPY_PLAN]
                .subscribe( ( { data } ) => onCopyPlan( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.REMOVE_PLAN]
                .subscribe( ( { data } ) => onRemovePlan( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.LOCK_PLAN]
                .subscribe( ( { data } ) => onLockPlan( this._dataStream, data ) );

        eventStream[EVENT_KEYS_PLAN_MANAGEMENT.OPEN_PLAN]
                .subscribe( ( { data } ) => onOpenPlan( this._dataStream, data ) );

        // fetch patient data
        await this.fetchPatientData();

        // start polling loop
        this.pollUpdates();
    }

    async fetchPlansData() {
        const { stateStream } = this._dataStream;

        const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
        const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

        const { getPlans } = apiClient.apis.operationManage;

        try {
            const response = await ( getPlans( { caseUUID } ) );
            return response.ok ? response.obj : null;
        } catch {
            return null;
        }
    }

    async fetchDataApproved() {
        const { stateStream } = this._dataStream;

        const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
        const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

        const { getApproveStatus } = apiClient.apis.operationManage;

        try {
            const [
                isRegistrationApproved,
                isSegmentationApproved
            ] = ( await Promise.all( [
                getApproveStatus( { caseUUID, approveType: "registration" } ),
                getApproveStatus( { caseUUID, approveType: "segmentation" } )
            ] ) ).map( response => response.ok ? response.obj : false );

            return isRegistrationApproved && isSegmentationApproved;
        } catch {
            return false;
        }
    }

    async fetchPatientData() {
        const { stateStream } = this._dataStream;

        const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
        const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

        const statePatientAge = stateStream[STATE_KEYS_APP.PATIENT_AGE];
        const statePatientSex = stateStream[STATE_KEYS_APP.PATIENT_SEX];

        const { getCasePatient } = apiClient.apis.operationManage;

        try {
            const patientResponse = await getCasePatient( { caseUUID } );
            const {
                age,
                sex
            } = patientResponse.obj;

            statePatientAge.emit( age );
            statePatientSex.emit( sex );
        } catch ( err ) {
            console.error( "Could not retrieve patient information from server.", err );
        }
    }

    async pollUpdates() {
        const { stateStream } = this._dataStream;

        const isWaiting = stateStream[STATE_KEYS_PLAN_MANAGEMENT.PLAN_MANAGEMENT_LOADING].current;

        // skip data polling if we are within an operation
        if ( !isWaiting ) {
            await this.pollUpdatesCallback();
        }

        // we poll for updates every 10 seconds
        const pollTimeMs = 10000;
        setTimeout( this.pollUpdates.bind( this ), pollTimeMs );
    }

    async pollUpdatesCallback() {
        const { stateStream } = this._dataStream;

        const stateCasePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS];
        const stateDataApproved = stateStream[STATE_KEYS_PLAN_MANAGEMENT.DATA_APPROVED];

        const currCasePlans = stateCasePlans.current || [];
        const currDataApproved = stateDataApproved.current;

        // if case is approved do not poll for fetchIsApproved anymore
        const [
            nextCasePlans,
            nextDataApproved
        ] = await Promise.all( [
            this.fetchPlansData(),
            currDataApproved ? true : this.fetchDataApproved()
        ] );

        // dataApproved changed
        //  only emit new values if value really changed
        if ( currDataApproved !== nextDataApproved ) {
            stateDataApproved.emit( nextDataApproved );
        }

        // casePlans changed
        //  only emit new values if value really changed
        const nextCasePlansStack = nextCasePlans.slice();
        let plansDataChanged = currCasePlans.length !== nextCasePlans.length;
        if ( !plansDataChanged ) {
            plansDataChanged = currCasePlans.reduce( ( accValue, currPlan ) => {
                if ( accValue ) {
                    return true;
                }

                const nextPlanIndex = indexByUUID( nextCasePlansStack, currPlan.uuid );
                if ( nextPlanIndex === -1 ) {
                    return true;
                }

                const [nextPlan] = nextCasePlansStack.splice( nextPlanIndex, 1 );

                if ( currPlan.name !== nextPlan.name ||
                    currPlan.isLocked !== nextPlan.isLocked ||
                    currPlan.updatedAt !== nextPlan.updatedAt
                ) {
                    return true;
                }

                return false;
            }, false );
        }

        if ( plansDataChanged ) {
            stateCasePlans.emit( nextCasePlans );
        }
    }
};

export { PlanManagementController };