import PropTypes from "prop-types";
import React from "react";

import {
    Redirect,
    Route,
    Switch,
    useParams
} from "react-router-dom";

import {
    PassThroughStateObserver,
    connectDataStream
} from "@ts/datastream";

import {
    APP_LOADER_TYPE,
    AppLoader,
    AppMain,
    INPROGRESS_TYPE,
    InProgress,
    NotFound
} from "@neurovis/ui-components";

import {
    CASE_DIAGNOSIS_TYPE,
    CASE_STATUS_TYPE
} from "@neurovis/config";

import { STATE_KEYS_APP } from "../dataStreamConfig.js";

import { CreateCaseController } from "../createCase/CreateCaseController.js";
import { DownloadResultsController } from "../downloadResults/DownloadResultsController.js";
import { PlanManagementController } from "../planManagement/PlanManagementController.js";


const ContentContainer = ( {
    dataStream,
    caseDiagnosis
} ) => {

    if ( caseDiagnosis === null ) {
        // on the initial render the caseDiagnosis has not yet been fetched from the server
        return null;
    }

    if ( caseDiagnosis === CASE_DIAGNOSIS_TYPE.ANEURYSM ) {
        const controller = new DownloadResultsController( dataStream );
        const DownloadComponent = controller.getComponent();
        return <DownloadComponent />;
    }

    if ( caseDiagnosis === CASE_DIAGNOSIS_TYPE.TUMOR ) {
        const planManagementController = new PlanManagementController( dataStream );
        const PlanManagementComponent = planManagementController.getComponent();
        return <PlanManagementComponent />;
    }
    console.error( `Unknown Diagnosis ${caseDiagnosis}` );
    return null;
};

ContentContainer.propTypes = {
    caseDiagnosis: PropTypes.string,
    dataStream: PropTypes.object.isRequired
};

const ResultContent = connectDataStream( {
    getObservers: dataStream => [{
        stateKey: STATE_KEYS_APP.CASE_DIAGNOSIS,
        callback: emission => ( {
            caseDiagnosis: emission.current,
            dataStream
        } )
    }]
} )( ContentContainer );


const AppComponent = props => {
    const {
        [STATE_KEYS_APP.CASE_STATUS]: caseStatus,
        setCaseUUID,
        appDataStream
    } = props;

    const { caseUUID } = useParams();
    setCaseUUID( caseUUID );

    const isLoading = !caseStatus;

    let caseWorkflowComponent = null;
    if ( caseStatus === CASE_STATUS_TYPE.NEW ) {
        const createCaseController = new CreateCaseController( appDataStream );
        const CreateCaseComponent = createCaseController.getComponent();
        caseWorkflowComponent = <CreateCaseComponent />;
    } else if ( caseStatus === CASE_STATUS_TYPE.IN_PROGRESS ) {
        caseWorkflowComponent = <InProgress inprogressType={INPROGRESS_TYPE.PROGRESS}/>;
    } else if ( caseStatus === CASE_STATUS_TYPE.IN_REVIEW ) {
        caseWorkflowComponent = <InProgress inprogressType={INPROGRESS_TYPE.REVIEW}/>;
    } else if ( caseStatus === CASE_STATUS_TYPE.READY ) {
        caseWorkflowComponent = <ResultContent />;
    }

    const loadingElement = isLoading ? (
        <AppLoader appLoaderType={APP_LOADER_TYPE.CASE}/>
    ) : null;

    return (
        <React.Fragment>
            {loadingElement}
            {caseWorkflowComponent}
        </React.Fragment>
    );
};

AppComponent.propTypes = {
    [STATE_KEYS_APP.CASE_STATUS]: PropTypes.string,
    appDataStream: PropTypes.object.isRequired,
    setCaseUUID: PropTypes.func.isRequired
};

const getEmitters = ( dataStream, props ) => ( {
    setCaseUUID: nextCaseUUID => {
        const stateCaseUUID = dataStream.stateStream[STATE_KEYS_APP.CASE_UUID];
        const lastCaseUUID = stateCaseUUID.current;

        if ( nextCaseUUID !== lastCaseUUID ) {
            stateCaseUUID.emit( nextCaseUUID );
        }
    }
} );

const getObservers = ( dataStream, props ) => [
    {
        stateKey: STATE_KEYS_APP.CASE_STATUS,
        callback: emission => ( {
            [STATE_KEYS_APP.CASE_STATUS]: emission.current,
            appDataStream: dataStream
        } )
    }
];

const AppContainer = connectDataStream( {
    getEmitters,
    getObservers
} )( AppComponent );

const AppRouterComponent = props => {
    /* eslint-disable-next-line */
    const publicPath = __webpack_public_path__;

    return (
        <AppMain>
            <Switch>
                <Route path={`${publicPath}notFound`}>
                    <NotFound />
                </Route>
                <Route path={`${publicPath}cases/:caseUUID`}>
                    <AppContainer />
                </Route>
                <Route>
                    <Redirect to={`${publicPath}notFound`}/>
                </Route>
            </Switch>
        </AppMain>
    );
};

export { AppRouterComponent as AppContainer };