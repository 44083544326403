import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";

import { AppController } from "./app/AppController.js";

// strict mode will render components twice
const USE_STRICT_MODE = false;

const App = props => {
    const appController = new AppController();
    const AppComponent = appController.getComponent();

    return ( <AppComponent /> );
};

const Wrapper = USE_STRICT_MODE ? React.StrictMode : React.Fragment;
ReactDOM.render( (
    <Wrapper>
        <Router>
            <App />
        </Router>
    </Wrapper>
), document.getElementById( "root" ) );
