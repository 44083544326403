import {
    DataStream,
    createDataStreamElements,
    provideDataStream
} from "@ts/datastream";

import {
    CREATE_CASE_UPLOAD_TASK_TYPE,
    DIAGNOSIS_PRESET_CONFIG_MAP,
    MODALITY_TYPE,
    STRUCTURE_MODALITY_RELATION_CONFIG
} from "@neurovis/config";

import {
    EVENT_KEYS_CREATE_CASE,
    STATE_CREATE_CASE_DEFAULTS,
    STATE_KEYS_APP,
    STATE_KEYS_CREATE_CASE
} from "../dataStreamConfig.js";

import { CreateCaseContainer } from "./CreateCaseContainer.jsx";

import {
    getFileNamesFromFileList,
    readFileListAsArrayBuffers,
    zipArrayBuffers
} from "../fileUtils";

import {
    dateToIsoDateString,
    isIsoDateString,
    isoDateStringToGbDateString
} from "@ts/dateutils";

import { formDataOnProgress } from "../apiClientUtils.js";


const setModalityDefaults = ( dataStream, modalityType ) => {
    const { stateStream } = dataStream;

    stateStream[ STATE_KEYS_CREATE_CASE[`FILES_${modalityType}`]].emit( null );
    stateStream[ STATE_KEYS_CREATE_CASE[`FILES_VALID_${modalityType}`]].emit( false );
    stateStream[ STATE_KEYS_CREATE_CASE[`IMAGE_DATA_${modalityType}`]].emit( null );
    stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_${modalityType}`]].emit( null );
    stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_VALID_${modalityType}`]].emit( true );
};

const STEP_COUNT = 4;

const CreateCaseController = class {
    constructor( parentDataStream ) {
        console.log( "CreateCaseController.constructor" );

        this._parentDataStream = parentDataStream;
        this._localDataStream = new DataStream();
        createDataStreamElements( {
            dataStream: this._localDataStream,
            stateKeys: STATE_KEYS_CREATE_CASE,
            defaultStates: STATE_CREATE_CASE_DEFAULTS,
            eventKeys: EVENT_KEYS_CREATE_CASE
        } );

        this._dataStream = new DataStream();
        this._dataStream.merge( this._parentDataStream );
        this._dataStream.merge( this._localDataStream );

        this._component = provideDataStream( this._dataStream )( CreateCaseContainer );

        this.init();
    }

    get dataStream() {
        return this._dataStream;
    }

    getComponent() {
        return this._component;
    }

    init() {
        console.log( "CreateCaseController.init" );

        const {
            eventStream,
            stateStream
        } = this._dataStream;

        const stateStepIndex = stateStream[STATE_KEYS_CREATE_CASE.STEP_INDEX];

        const stateStepCompleteAgreement = stateStream[STATE_KEYS_CREATE_CASE.COMPLETE_AGREEMENT];
        const stateApprovedTermsAndConditions = stateStream[STATE_KEYS_CREATE_CASE.APPROVED_TAC];
        const stateApprovedDataProcessingAgreement = stateStream[STATE_KEYS_CREATE_CASE.APPROVED_DPA];

        const stateStepCompleteFindings = stateStream[STATE_KEYS_CREATE_CASE.COMPLETE_FINDINGS];
        const stateCaseDiagnosis = stateStream[STATE_KEYS_APP.CASE_DIAGNOSIS];
        const stateCasePresetNumber = stateStream[STATE_KEYS_APP.CASE_PRESET_NUMBER];
        const statePresetOptionalStructures = stateStream[STATE_KEYS_CREATE_CASE.PRESET_OPTIONAL_STRUCTURES];

        const stateStepCompleteInformation = stateStream[STATE_KEYS_CREATE_CASE.COMPLETE_INFORMATION];
        const stateCaseName = stateStream[STATE_KEYS_APP.CASE_NAME];
        const stateCaseNameValid = stateStream[STATE_KEYS_CREATE_CASE.CASE_NAME_VALID];
        const stateCaseNotes = stateStream[STATE_KEYS_APP.CASE_NOTES];
        const statePatientAge = stateStream[STATE_KEYS_APP.PATIENT_AGE];
        const statePatientAgeValid = stateStream[STATE_KEYS_CREATE_CASE.PATIENT_AGE_VALID];
        const statePatientSex = stateStream[STATE_KEYS_APP.PATIENT_SEX];

        const stateStepCompleteImageData = stateStream[STATE_KEYS_CREATE_CASE.COMPLETE_IMAGEDATA];
        const stateSelectedModalities = stateStream[STATE_KEYS_CREATE_CASE.SELECTED_MODALITIES];

        const stateUploadModalOpen = stateStream[STATE_KEYS_CREATE_CASE.UPLOAD_MODAL_OPEN];

        const eventCreateCase = eventStream[EVENT_KEYS_CREATE_CASE.CREATE_CASE];
        const eventChangeStep = eventStream[EVENT_KEYS_CREATE_CASE.CHANGE_STEP];
        const eventResetDiagnosis = eventStream[EVENT_KEYS_CREATE_CASE.RESET_DIAGNOSIS];
        const eventStartUpload = eventStream[EVENT_KEYS_CREATE_CASE.START_UPLOAD];

        eventCreateCase.subscribe( emission => {
            stateStepIndex.emit( 0 );
        } );

        // Agreement step handlers
        // ---

        const checkAgreementComplete = () => {
            const approvedTermsAndConditions = stateApprovedTermsAndConditions.current;
            const approvedDataProcessingAgreement = stateApprovedDataProcessingAgreement.current;

            const isComplete = approvedTermsAndConditions && approvedDataProcessingAgreement;
            stateStepCompleteAgreement.emit( isComplete );
        };
        stateApprovedTermsAndConditions.subscribe( checkAgreementComplete );
        stateApprovedDataProcessingAgreement.subscribe( checkAgreementComplete );

        // Findings step handlers
        // ---

        const checkFindingsComplete = () => {
            const caseDiagnosis = stateCaseDiagnosis.current;
            const casePresetNumber = stateCasePresetNumber.current;

            const isComplete = caseDiagnosis && casePresetNumber;
            stateStepCompleteFindings.emit( isComplete );
        };
        stateCaseDiagnosis.subscribe( checkFindingsComplete );
        stateCasePresetNumber.subscribe( checkFindingsComplete );

        const chooseAppropriateModalities = () => {
            const caseDiagnosis = stateCaseDiagnosis.current;
            const casePresetNumber = stateCasePresetNumber.current;
            if ( caseDiagnosis && casePresetNumber ) {

                const { structuresCompulsory } = DIAGNOSIS_PRESET_CONFIG_MAP[caseDiagnosis][casePresetNumber];
                const compulsory = structuresCompulsory
                        .flatMap( struct => STRUCTURE_MODALITY_RELATION_CONFIG[struct].compulsory )
                        .filter( ( v, i, arr ) => arr.indexOf( v ) === i );

                stateSelectedModalities.emit( compulsory );
            }
        };

        stateCaseDiagnosis.subscribe( chooseAppropriateModalities );
        stateCasePresetNumber.subscribe( chooseAppropriateModalities );

        eventResetDiagnosis.subscribe( emission => {
            stateCaseDiagnosis.emit( null );
            stateCasePresetNumber.emit( null );
            statePresetOptionalStructures.emit( [] );

            // we remove the selected image data, modalities
            stateSelectedModalities.emit( [ ] );
            Object.values( MODALITY_TYPE )
                    .forEach( modalityType => setModalityDefaults( this._dataStream, modalityType ) );

            checkFindingsComplete();
        } );

        // Information step handlers
        // ---

        // initialize caseName
        //  TODO: find better way of passing the react text hook to the controller
        eventStream[EVENT_KEYS_CREATE_CASE.GET_FORMATTED_TEXT_HOOK]
                .subscribe( ( { data: getFormattedText } ) => {
                    if ( stateCaseName.current !== null &&
                stateCaseName.current !== undefined
                    ) {
                        return;
                    }

                    const initialCaseName = getFormattedText( "plansOverviewCaseInformationNameDefault", {
                        date: isoDateStringToGbDateString( dateToIsoDateString( new Date() ) )
                    } ).content;
                    stateCaseName.emit( initialCaseName );
                } );

        stateCaseName.subscribe( ( { current } ) => {
            const caseNameValid = !!current.length;
            stateCaseNameValid.emit( caseNameValid );
        } );

        statePatientAge.subscribe( ( { current } ) => {
            // false positives for null values eg: 2e, 2.
            let patientAgeValid = current === null || current >= 0;
            statePatientAgeValid.emit( patientAgeValid );
        } );

        const checkInformationComplete = () => {
            const caseNameValid = stateCaseNameValid.current;
            const patientAgeValid = statePatientAgeValid.current;

            const stepCompleteFindings = stateStepCompleteFindings.current;

            const isComplete = caseNameValid && patientAgeValid && stepCompleteFindings;
            stateStepCompleteInformation.emit( isComplete );
        };
        stateCaseNameValid.subscribe( checkInformationComplete );
        statePatientAgeValid.subscribe( checkInformationComplete );
        stateStepCompleteFindings.subscribe( checkInformationComplete );

        // Image data step handlers
        // ---

        const checkImageDataComplete = () => {
            const selectedModalities = stateSelectedModalities.current;
            let imageDataValid = true;
            selectedModalities.forEach( modalityType => {
                const filesValid = stateStream[ STATE_KEYS_CREATE_CASE[`FILES_VALID_${modalityType}`]].current;
                const acqDateValid = stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_VALID_${modalityType}`]].current;

                imageDataValid &= filesValid && acqDateValid;
            } );

            stateStepCompleteImageData.emit( imageDataValid );
        };

        Object.values( MODALITY_TYPE ).forEach( modalityType => {
            const stateFiles = stateStream[ STATE_KEYS_CREATE_CASE[`FILES_${modalityType}`]];
            const stateFilesValid = stateStream[ STATE_KEYS_CREATE_CASE[`FILES_VALID_${modalityType}`]];
            const stateImageData = stateStream[ STATE_KEYS_CREATE_CASE[`IMAGE_DATA_${modalityType}`]];
            const stateAcqDate = stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_${modalityType}`]];
            const stateAcqDateValid = stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_VALID_${modalityType}`]];

            stateFiles.subscribe( ( { current } ) => {
                const hasFiles = current && !!current.length;

                if ( !hasFiles ) {
                    // reset acq date and image data
                    stateAcqDate.emit( null );
                    stateAcqDateValid.emit( true );

                    stateImageData.emit( null );
                    stateFilesValid.emit( false );

                    return;
                }

                stateFilesValid.emit( hasFiles );

                // TODO:
                //  * add parsing of files
                //  * files are valid if parsing succeeded
                //  * automatically populate acq date
                //  * create image data from parsed data
                //    * for preview functionality
                //    * image data == parsed data
            } );

            stateAcqDate.subscribe( ( { current } ) => {
                const acqDateValid = current === null || isIsoDateString( current );
                stateAcqDateValid.emit( acqDateValid );
            } );

            stateFilesValid.subscribe( checkImageDataComplete );
            stateAcqDateValid.subscribe( checkImageDataComplete );
        } );

        stateSelectedModalities.subscribe( ( { current } ) => {
            Object.values( MODALITY_TYPE )
                    .filter( modalityType => current.indexOf( modalityType ) === -1 )
                    .forEach( modalityType => setModalityDefaults( this._dataStream, modalityType ) );

            checkImageDataComplete();
        } );

        eventChangeStep.subscribe( emission => {
            const nextStepIndex = emission.data;

            // when clicked progress on last step
            //  open the upload modal
            if ( nextStepIndex === STEP_COUNT ) {
                stateUploadModalOpen.emit( true );
                return;
            }

            const lastStepIndex = stateStepIndex.current;
            if ( nextStepIndex < lastStepIndex ) {
                // TODO: reset necessary states when going back
                //  currently this is not needed
            }

            stateStepIndex.emit( nextStepIndex );
        } );

        const handleUpload = async() => {
            // gather information
            const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
            const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

            const caseName = stateCaseName.current;
            const caseNotes = stateCaseNotes.current;
            const caseDiagnosis = stateCaseDiagnosis.current;
            const casePresetNumber = stateCasePresetNumber.current;
            const patientAge = statePatientAge.current;
            const patientSex = statePatientSex.current;
            const presetOptionalStructures = statePresetOptionalStructures.current;
            const selectedModalities = stateSelectedModalities.current;

            // We do not allow the user to have the option if the tentorium
            //  should be segmented or not. The tentorium is set to be always segmented.

            // const caseHasTentorium = presetOptionalStructures.indexOf( STRUCTURE_TYPE.TENTORIUM ) !== -1;
            const caseHasTentorium = true;

            const {
                uploadCaseImageData,
                updateCasePatient,
                updateCase,
                setApproval
            } = apiClient.apis.operationManage;

            stateStream.uploadStatus.emit( {
                uploading: true,
                percent: 0,
                task: CREATE_CASE_UPLOAD_TASK_TYPE.CASE_DATA_UPLOAD,
                modality: null
            } );

            // before uploading any data set approvals for reading the conditions
            await setApproval( {
                caseUUID,
                approveType: "termsAndCondition"
            } );
            await setApproval( {
                caseUUID,
                approveType: "dataProcessingPolicy"
            } );

            // update case and patient information
            await updateCase( {
                caseUUID,
                body: {
                    name: caseName,
                    notes: caseNotes,
                    diagnosis: caseDiagnosis,
                    presetNumber: casePresetNumber,
                    hasTentorium: caseHasTentorium
                }
            } );

            await updateCasePatient( {
                caseUUID,
                body: {
                    age: patientAge,
                    sex: patientSex
                }
            } );

            // upload all image data
            // to minimize ram usage iterate over the imageData
            let totalUploads = selectedModalities.length;
            let finishedUploads = 0;
            const processImageData = async modalityType => {
                stateStream.uploadStatus.emit( {
                    uploading: true,
                    percent: finishedUploads * 100 / totalUploads + ( 100 / totalUploads * 0.25 ),
                    task: CREATE_CASE_UPLOAD_TASK_TYPE.MODALITY_PREPARE,
                    modality: modalityType
                } );

                const fileList = stateStream[ STATE_KEYS_CREATE_CASE[`FILES_${modalityType}`]].current;
                const acqDate = stateStream[ STATE_KEYS_CREATE_CASE[`ACQ_DATE_${modalityType}`]].current;

                //  read slice files from filelist
                const fileNames = getFileNamesFromFileList( fileList );
                const fileArrayBuffers = await readFileListAsArrayBuffers( fileList );

                //  create zip file
                const zipArrayBuffer = await zipArrayBuffers( {
                    fileArrayBuffers,
                    fileNames,
                    compressionLevel: 9
                } );

                const zipFile = new File( [zipArrayBuffer],
                    `${modalityType}.zip`, { type: "application/x-zip-compressed" } );

                stateStream.uploadStatus.emit( {
                    uploading: true,
                    percent: finishedUploads * 100 / totalUploads + ( 100 / totalUploads * 0.5 ),
                    task: CREATE_CASE_UPLOAD_TASK_TYPE.MODALITY_PREPARE,
                    modality: modalityType
                } );

                //  upload case image data with zip file
                await uploadCaseImageData( {
                    caseUUID,
                    modalityType,
                    acquisitionDate: acqDate,
                    imageData: zipFile
                }, formDataOnProgress( event => {
                    const {
                        loaded,
                        total
                    } = event;

                    const nextPercentage = finishedUploads * 100 / totalUploads +
                        ( 100 / totalUploads * 0.5 ) +
                        ( 100 / totalUploads * loaded / total * 0.5 );

                    stateStream.uploadStatus.emit( {
                        uploading: true,
                        percent: nextPercentage,
                        task: CREATE_CASE_UPLOAD_TASK_TYPE.MODALITY_PREPARE,
                        modality: modalityType
                    } );
                } ) );
            };

            for ( let i = 0; i < selectedModalities.length;i++ ) {
                await processImageData( selectedModalities[i] );
                finishedUploads++;
            }

            // finally set data completeness approval
            await setApproval( {
                caseUUID,
                approveType: "dataCompleteness"
            } );

            stateUploadModalOpen.emit( false );
            stateStepIndex.emit( STEP_COUNT );
        };
        eventStartUpload.subscribe( handleUpload );
    }
};

export { CreateCaseController };