import {
    STATE_KEYS_APP,
    STATE_KEYS_PLAN_MANAGEMENT
} from "../dataStreamConfig.js";

const indexByUUID = ( array, uuid ) => array.findIndex( item => item.uuid === uuid );

const setLoadingState = ( dataStream, isLoading ) => {
    const { stateStream } = dataStream;
    const stateLoading = stateStream[STATE_KEYS_PLAN_MANAGEMENT.PLAN_MANAGEMENT_LOADING];
    stateLoading.emit( isLoading );
};

const onAddPlan = async( dataStream, data ) => {
    await onCopyPlan( dataStream, data );
};

const onCopyPlan = async( dataStream, data ) => {
    setLoadingState( dataStream, true );

    const { stateStream } = dataStream;

    const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
    const { createPlan } = apiClient.apis.operationManage;

    const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;
    const planUUID = data ? data.uuid : undefined;
    const planName = data ? data.name : undefined;

    const stateCasePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS];

    try {
        const response = await createPlan( {
            caseUUID,
            // if planUUID is defined we duplicate a plan
            "x-copy-source": planUUID,
            body: { name: planName }
        } );

        if ( response.ok ) {
            const currCasePlans = stateCasePlans.current;
            const nextPlansData = currCasePlans.slice();
            nextPlansData.push( response.obj );
            stateCasePlans.emit( nextPlansData );
        }
    } catch ( err ) {
        console.error( "Could not create or copy plan.", err );
    }

    setLoadingState( dataStream, false );
};

const onLockPlan = async( dataStream, data ) => {
    setLoadingState( dataStream, true );

    const { stateStream } = dataStream;

    const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
    const { updatePlan } = apiClient.apis.operationManage;

    const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;
    const planUUID = data.uuid ;
    const isLocked = data.isLocked ;

    const stateCasePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS];

    try {
        const response = await updatePlan( {
            caseUUID,
            planUUID,
            body: { isLocked }
        } );

        if ( response.ok ) {
            const currCasePlans = stateCasePlans.current;
            const nextPlansData = currCasePlans.slice();

            const planIndex = indexByUUID( nextPlansData, planUUID );
            if ( planIndex !== -1 ) {
                nextPlansData[planIndex] = response.obj;
            }
            stateCasePlans.emit( nextPlansData );
        }
    } catch ( err ) {
        console.error( "Could not change lock state of the plan.", err );
    }

    setLoadingState( dataStream, false );
};

const onOpenPlan = async( dataStream, data ) => {
    const { stateStream } = dataStream;

    const { uuid: planUUID } = data;

    const securityToken = stateStream[STATE_KEYS_APP.SECURITY_TOKEN].current;
    const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;

    const plannerEndpoint = stateStream[STATE_KEYS_PLAN_MANAGEMENT.PLANNER_ENDPOINT].current;
    const plannerOrigin = stateStream[STATE_KEYS_PLAN_MANAGEMENT.PLANNER_ORIGIN].current;

    const plannerUrl = `${plannerOrigin}${plannerEndpoint}/cases/${caseUUID}/plans/${planUUID}?securityToken=${securityToken}`;

    const tab = window.open( plannerUrl, "_blank" );
    if ( tab ) {
        //Browser has allowed it to be opened
        tab.focus();
    } else {
        //Browser has blocked it
        console.warn( "Popup creation was blocked by browser" );
    }
};

const onRemovePlan = async( dataStream, data ) => {
    setLoadingState( dataStream, true );

    const { stateStream } = dataStream;

    const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
    const { deletePlan } = apiClient.apis.operationManage;

    const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;
    const planUUID = data.uuid ;

    const stateCasePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS];

    try {
        const response = await deletePlan( {
            caseUUID,
            planUUID
        } );

        if ( response.ok ) {
            const currCasePlans = stateCasePlans.current;
            const nextPlansData = currCasePlans.slice();

            const removeIndex = indexByUUID( nextPlansData, planUUID );
            if ( removeIndex !== -1 ) {
                nextPlansData.splice( removeIndex, 1 );
            }
            stateCasePlans.emit( nextPlansData );
        }
    } catch ( err ) {
        console.error( "Could not remove the plan.", err );
    }

    setLoadingState( dataStream, false );
};

const onRenamePlan = async( dataStream, data ) => {
    setLoadingState( dataStream, true );

    const { stateStream } = dataStream;

    const apiClient = stateStream[STATE_KEYS_APP.API_CLIENT].current;
    const { updatePlan } = apiClient.apis.operationManage;

    const caseUUID = stateStream[STATE_KEYS_APP.CASE_UUID].current;
    const planUUID = data.uuid ;
    const planName = data.name ;

    const stateCasePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS];

    try {
        const response = await updatePlan( {
            caseUUID,
            planUUID,
            body: {
                name: planName
            }
        } );

        if ( response.ok ) {
            const currCasePlans = stateCasePlans.current;
            const nextPlansData = currCasePlans.slice();

            const planIndex = indexByUUID( nextPlansData, planUUID );
            if ( planIndex !== -1 ) {
                nextPlansData[planIndex] = response.obj;
            }
            stateCasePlans.emit( nextPlansData );
        }
    } catch ( err ) {
        console.error( "Could not rename the plan.", err );
    }

    setLoadingState( dataStream, false );
};

const onReviewData = async( dataStream, data ) => {
    const { stateStream } = dataStream;
    const casePlans = stateStream[STATE_KEYS_PLAN_MANAGEMENT.CASE_PLANS].current;
    if ( !casePlans.length ) {
        return;
    }

    await onOpenPlan( dataStream, { uuid: casePlans[0].uuid } );
};

export {
    onAddPlan,
    onCopyPlan,
    onLockPlan,
    onOpenPlan,
    onRemovePlan,
    onRenamePlan,
    onReviewData
};