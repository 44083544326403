import Swagger from "swagger-client";

const retrieveSecurityToken = () => {
    const searchParams = new URLSearchParams( location.search );
    return searchParams.get( "securityToken" );
};

let swaggerJson = null;
const createApiClient = async( {
    securityToken,
    apiOrigin,
    apiEndpoint
} = {} ) => {
    const apiUrl = `${apiOrigin}${apiEndpoint}`;
    let swagger = null;

    try {
        // only fetch api docs once
        if ( !swaggerJson ) {
            const response = await fetch( apiUrl );
            swaggerJson = await response.json();
        }

        swagger = await new Swagger( {
            spec: swaggerJson,
            authorizations: {
                SecurityTokenHeader: securityToken
            }
        } );
    } catch ( err ) {
        console.error( "Could not create api client: ", err );
    }

    // this activates CORS
    // swagger.http.withCredentials = "include";

    return swagger;
};

const getHeadersAsObject = xhr => {
    const headers = new Map();
    xhr.getAllResponseHeaders()
            .split( "\u000d\u000a" )
            .forEach( line => {
                if ( line.length > 0 ) {
                    const delimiter = "\u003a\u0020";
                    const header = line.split( delimiter );
                    headers.set( header.shift().toLowerCase(), header.join( delimiter ) );
                }
            } );
    return headers;
};

// see: https://github.com/swagger-api/swagger-js/issues/1241
const formDataOnProgress = onProgress => ( {
    requestInterceptor: req => {
        req.userFetch = ( url, options ) => new Promise( ( resolve, reject ) => {
            const xhr = new XMLHttpRequest();
            xhr.open( options.method, url );
            xhr.onload = () => {
                resolve( {
                    ok: true,
                    url: xhr.responseURL,
                    status: xhr.status,
                    statusText: xhr.statusText,
                    headers: getHeadersAsObject( xhr ),
                    text: obj => Promise.resolve( xhr.body )
                } );
            };
            xhr.onerror = () => reject( xhr.statusText );
            xhr.upload.addEventListener( "progress", onProgress, false );
            for ( const k in options.headers ) {
                if ( options.headers.hasOwnProperty( k ) ) {
                    xhr.setRequestHeader( k, options.headers[k] );
                }
            }
            xhr.send( options.body );
        } );
        return req;
    }
} ) ;

export {
    createApiClient,
    retrieveSecurityToken,
    formDataOnProgress
};
